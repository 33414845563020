@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.rightText{
  text-align: right !important;
}
.CenterContect{
  margin: auto;
}
.WelcomePageTitle{
  margin-bottom: 0px !important;
}
.Multi{
  font-size:22px;
}
.mainatractContect a{
  cursor: pointer;
}
.StatisticCube{
  margin-bottom: 20px;

}
.StatisticCube2{
  margin-bottom: 20px;
  height: 320px;

}

.OrderTable ul{
  text-align: right;
}
.hidden{
  display: none !important;
}
.UploadImage{
  width: 255px;
height: 40px;
background: #40AE49 0% 0% no-repeat padding-box;
border-radius: 4px;
color: white;
font-weight: bold;
text-align: center;
padding-top: 1%;
cursor: pointer;
  
}

.downloadincvoice{
  height: 62px;
  cursor: pointer;
}
.StatisticDats{
  width: 400px;
  display: block;
  margin: auto;
}
.App {
  direction: rtl !important;
  font-family: 'Open Sans Hebrew', serif !important;
  font-size: 16px;
}
.boldli{
  font-weight: 800;
  font-size: 17px;

}
.App-header img{
  display: block;
  margin: 0 auto;
  
}
.CouponMID{
  width: 300px !important;
  margin: auto;
  
}
.contect{

  justify-content: center;
  align-items: center;
  text-align: center;

}
.contect h1{
  margin-top: 15px;
  margin-bottom: 40px;
  font-weight: 800;

}
.StatisticCube{
  min-height: 130px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  padding: 35px;
  margin-top: 35px;


}
.StatisticCube2{
  min-height: 130px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  text-align: center;
  padding: 35px;
  margin-top: 35px;

}

.App-header{
 
  background: #E9E7D7 0% 0% no-repeat padding-box;
  padding: 5px 0px 5px 0px;
  

}
[type=email], [type=number], [type=tel], [type=url]{
  direction: rtl !important;
}
.login{

}
#stickylogo{
  position: absolute;
  bottom: 60px;
  left: 15px;
}
.logincenter{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.logincenter .btn{
  background: #40AE49 0% 0% no-repeat padding-box;
  border-color: white !important;
  border-radius: 4px;

}
.logincenter .btn:hover{
  background: #40AE49 0% 0% no-repeat padding-box;
}
.login input{
 



}
a { color: inherit !important;
  text-decoration: none !important;
} 
.sidebar ul{

    list-style-type: none;
  
}
.sidebar ul li{

padding: 10px;
}
.sidebar {
  position: fixed;

  bottom: 0;
  right: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 100px 0 0;

  background: #F2F1E8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  width: 16.66666667%;

}


.controllogin{
  width: 320px;
  margin-top: 20px;

}
.StatisticCube h4{
  color: black;
  font-size: 17px;


}
.StatisticCube2 h4{
  color: black;
  font-size: 17px;

}

.StatisticCube span{
  color: #40AE49;
  font-size: 30px;
  font-weight: 800;


}
.StatisticCube2 span{
  color: #40AE49;
  font-size: 30px;
  font-weight: 800;


}

.insideloginform{
  display: block;
  margin: 0 auto;
}

.react-datepicker-popper{
  inset:auto !important;
  -webkit-transform: translate(0px,0px) !important;
          transform: translate(0px,0px) !important;


}

/* main atract */

.h1mainatract{
  margin-top: 45px;
  font-size: 20px;
  font-weight: 800;
  display: inline-flex;
}


.h1mainatract:before{
  content: '';
  display: block;
  height: 25px;
  width: 4px;
  background-color: #40AE49;
  margin: 0 0 0 7px;
border-radius: 3px;

}
.mainatractContect h2{
  text-align: center;
  font-size: 25px;

}
.mainatractContect{
  width: 60%;
  margin: auto;
}
.atractinput1{
  width: 600px !important;
  margin-top: 30px;
}
.atractinputOpenTime{
  margin-top: 40px;


}
.page-item.active .page-link{
  background-color: #40AE49  !important;
    border-color: #40AE49  !important;

}
.openHoursh2{
  position: absolute !important;
  
}
.addTypeBTN{
  cursor: pointer;
}
.Pointer{
  cursor: pointer;
}
.VisitorTypeDIV{
  background: #E8E8E8 0% 0% no-repeat padding-box;
  width: 90%;
  text-align: center;
  margin-top: 10px;
  height: 40px;
  border-radius: 4px;
  padding: 10px;
  font-weight: 800;
}
.moredataiicone{
  height: 25px;
  width: 25px;
  cursor: pointer;
}
.NoResults{
  text-align: center !important;
  font-weight: bold;
  font-size: 26px;

}
.form-switch .form-check-input{
  width: 40px !important;
  height: 20px !important;
  cursor: pointer !important;

}
.form-check-input:checked{
  background-color: #40AE49  !important;
    border-color: #40AE49  !important;

}
.SelectSort{
  width: 200px;
  margin-top: 20px;
}
.SelectSort h2{
  font-weight: bold;
  font-size: 25px;
}
.Xbtn{
  padding-top: 15px;
  font-weight: 800;
  color: red;
  cursor: pointer;
  font-size: 23px;
}
.SaveAtractbtn{
  width: 300px;
  margin: auto;
margin-top: 100px;
font-weight: 900 !important;
margin-bottom: 50px;

}
.OrderSearch button{
  background-color: #40AE49  !important;
  border-radius: 4px !important;
  color:white !important;
  border-color:white !important;
  

}
.SaveAtractbtnCoupon{
  width: 100%;
  margin: auto;
  height: 40px;
  margin-top: 10px;

font-weight: 900 !important;

}
.OrderTable{
  margin-top: 40px;
}
.OrderSearch{
  width: 70% !important;
  display: block;
  margin: auto;
  padding-top: 15px;
}
.TopGrey{
  width: 100%;
  height: 70px;
  background-color: #EEEFF1;
  border-radius: 5px;
}
.DelImage{
  cursor: pointer;
  position: absolute;

  
  z-index: 100;
}
.SubAtract{
  font-size: 26px;
}
.CouponList{
  margin-top: 50px !important;
}
.SelectSize{
  width: 550px !important;
}
.ShowMainImage{
  width: 300px;
  height: 300px;
  position: relative;
}
#formFileMultiple{
  width: 450px;
}
/* main atract end */


